<template>
  <blogs-categories-component />
</template>

<script>
import BlogsCategoriesComponent from "@/components/admin/catalogs/BlogsCategoriesComponent";

export default {
  name: "BlogsCategories",
  title: "Categorías Blogs | Baja California Health Tourism",
  components: { BlogsCategoriesComponent },
};
</script>

<style scoped></style>
